import React from 'react';
import classNames from 'classnames';

const ContainerFluid = React.forwardRef(({className, children}, ref) => {
  return (
    <div ref={ref} className={classNames("container-fluid", className)}>
      <div className="row">
        {children && children}
      </div>
    </div>
  );
});

export default ContainerFluid;