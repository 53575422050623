const header = {
    vi: {
        home: "Về chúng tôi",
        work: "Giới Thiệu",
        solution: "Giải pháp",
        news: "Tin Tức",
    },
    en: {
        home: "About us",
        work: "About us",
        solution: "Our solutions",
        news: "News",
    }
}

export default header