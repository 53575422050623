import classNames from "classnames";
import { motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import styles from "./Header.module.scss";

const spring = {
  type: "spring",
  stiffness: 400,
  damping: 30,
};

const variants = {
  appear: {
    opacity: 1,
    backgroundColor: "#44B5E5",
  },
};

const variantsActive = {
  active: {
    opacity: 1,
    backgroundColor: "#1D7BA3",
  },
};

const mb_variants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: "100%",
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
};

const MenuItem = ({
  onClick,
  isAnimate,
  setAnimate,
  title,
  link,
  isInView,
  isMobile = false,
}) => {
  const router = useRouter();
  return link ? (
    <motion.div
      className={styles.items}
      initial={false}
      variants={isMobile ? mb_variants : {}}
    >
      <Link href={link}>
        <div
          style={{ position: "relative" }}
          onMouseEnter={() => setAnimate(title)}
          onMouseLeave={() => setAnimate(undefined)}
          onClick={onClick}
        >
          <motion.div
            className={classNames(styles.menuItems)}
            initial={false}
            whileTap={{
              scale: 0.8,
            }}
            whileHover={{
              scale: 1.2,
            }}
          >
            {title}
          </motion.div>
          {isInView && window.innerWidth > 991 && (
            <motion.div
              layoutId="outlineActve"
              style={{
                height: "5px",
                width: "100%",
                backgroundColor: "rgb(29, 123, 163)",
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
              initial={false}
              animate={"open"}
              variants={variantsActive}
              transition={spring}
            ></motion.div>
          )}
          {isAnimate === title && (
            <motion.div
              layoutId="outline"
              style={{
                height: "5px",
                width: "100%",
                backgroundColor: "rgb(29, 123, 163)",
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
              initial={false}
              animate={"appear"}
              variants={variants}
              transition={spring}
            ></motion.div>
          )}
        </div>
      </Link>
    </motion.div>
  ) : (
    <motion.div
      className={styles.items}
      initial={false}
      variants={isMobile ? mb_variants : {}}
      style={{ position: "relative" }}
      onMouseEnter={() => setAnimate(title)}
      onMouseLeave={() => setAnimate(undefined)}
      onClick={onClick}
    >
      <motion.div
        className={classNames(styles.menuItems)}
        initial={false}
        whileTap={{
          scale: 0.8,
        }}
        whileHover={{
          scale: 1.2,
        }}
      >
        {title}
      </motion.div>
      {isInView && window.innerWidth > 991 && (
        <motion.div
          layoutId="outlineActve"
          style={{
            height: "5px",
            width: "100%",
            backgroundColor: "rgb(29, 123, 163)",
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
          initial={false}
          animate={"open"}
          variants={variantsActive}
          transition={spring}
        ></motion.div>
      )}
      {isAnimate === title && (
        <motion.div
          layoutId="outline"
          style={{
            height: "5px",
            width: "100%",
            backgroundColor: "rgb(29, 123, 163)",
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
          initial={false}
          animate={"appear"}
          variants={variants}
          transition={spring}
        ></motion.div>
      )}
    </motion.div>
  );
};

export default MenuItem;
