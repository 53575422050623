const langs = [
    {id: 1, vi: "Tiếng Việt", en: "Vietnamese", title: "vi", path: typeof window !== `undefined` ? window.location.origin + "/static/vn-flag.png" : "/static/vn-flag.png"},
    {id: 2, vi: "Tiếng Anh", en: "English", title: "en", path: typeof window !== `undefined` ? window.location.origin + "/static/uk-flag.png" : "/static/uk-flag.png"},
]

const findLang = (locale) => {
    const lang = langs.find(l => l.title === locale)
    return lang
}

export {
    langs,
    findLang
}