import React, { useEffect, useRef, useState } from 'react';

const useOutsideClick = (initialIsVisible = false) => {
  const [visible, setVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, visible, setVisible };
};

export default useOutsideClick;