import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
// import Image from 'react-imgix'
import Image from 'next/image'
import Lottie from 'react-lottie';
import styles from "./ImgComponent.module.scss"

const ImgComponent = ({
  layout, 
  objectFit, 
  width, 
  height, 
  src = "-", 
  alt="Estuary Solutions", 
  style, 
  jsonFile, 
  jsonSize, 
  ratio
}) => {
  const ref = useRef()
  const [estimateHeight, setHeight] = useState()
  const screenRatio = width / height
  const [parentSize, setParentSize] = useState()

  // useEffect(() => {
  //   let parentSize = document.querySelector(ratio)
  //   setParentSize(parentSize)
  // }, [ratio])
  
  // useEffect(() => {
  //   setHeight(ref.current.parentElement.offsetWidth / screenRatio)
  // }, [ref.current])

  // useEffect(() => {
  //   if(typeof window !== 'undefined'){
  //     console.log("ratio", ratio)
  //   }
  // })

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: jsonFile || undefined,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const calcDimension = (parentSize) => {
    console.log("calcDimension", parentSize?.offsetWidth)
    if(parentSize){
      if(jsonSize[0] > parentSize?.offsetWidth){
        return [parentSize.offsetWidth, parentSize.offsetWidth / (jsonSize[0] / jsonSize[1])]
      }
      else{
        return [jsonSize[0], jsonSize[1]]
      }
    }
    // if(typeof document !== "undefined"){
    //   let parentSize = parent?.offsetWidth ?? 0
    //   console.log("parentSize", parentSize)
    //   if(parentSize){
    //     if(jsonSize[0] > parentSize?.offsetWidth){
    //       return [parentSize.offsetWidth, parentSize?.offsetWidth / (jsonSize[0] / jsonSize[1])]
    //     }
    //     else{
    //       return [jsonSize[0], jsonSize[1]]
    //     }
    //   }
    // }
    // return [jsonSize[0], jsonSize[1]]
  }
  
  const renderImage = () => {
    return (
      jsonFile 
        ? 
        <div
          ref={ref}
          style={{
            ...style,
            display: "block",
            maxWidth: "100%",
            overflow: "hidden",
            position: "relative",
            boxSizing: "border-box",
            margin: "0",
          }}
        >
          <div className="lottie-wrapper">
            <div className="lottie-bg">
            </div>
            <Lottie options={defaultOptions}
              width={calcDimension(ratio)?.[0] ?? jsonSize[0]}
              height={calcDimension(ratio)?.[1] ?? jsonSize[1]}
              // height={270}
            />
          </div>
        </div>
        : 
        <Image
          className={classNames(styles.optimizeImg, objectFit && styles[objectFit])}
          src={src || ""}
          alt={alt}
          height={height}
          width={width}
          layout={layout}
        />
    )
  }
  return (
      renderImage()
  );
};

export default ImgComponent;