import classNames from 'classnames';
import { motion } from 'framer-motion';
import Image from '../ImgComponent';
import React, { useState } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import styles from './DropDownList.module.scss'
import { useRouter } from 'next/router';

const spring = {
  type: "spring",
  stiffness: 400,
  damping: 30,
};


const variants = {
  open: {
    scaleY: 1,
    originY: 0,
    originX: 0,
    transition: spring
  },
  closed: {
    opacity: 0,
    // scaleY: 0,
    // originY: 0,
    // originX: 0,
    transition: {
      duration: 0.01
    }
  }
}

const DropDownList = ({defaultSelected, data, render, onChange, wrapperClass, position, style = {}}) => {
  const [selected, setSelected] = useState(defaultSelected)
  const {ref, visible, setVisible} = useOutsideClick(false)
  const {locale} = useRouter()
  
  const renderData = (list) => {
    return list.reduce((result, item) => {
      if(item.id !== selected.id){
        result.push(
          <div title={item.en} key={item.id+""} onClick={() => onSelect(item)} className={classNames("cursor-pointer", styles.drop_down_item)}>
            {render 
              ? render(item) 
              : <Image width={30} height={30} src={item.path || ""} alt={item.name} />
            }
          </div>
        )
      }
      return result
    }, [])
  }
  const onSelect = (item) => {
    setSelected(item)
    setVisible(false)
    if(onChange) {
      onChange(item)
    }
  }
  const toggleSelect = () => {
    setVisible(!visible)
  }
  return (
    <div style={{...style}} ref={ref} className={classNames(styles.drop_down_wrapper, wrapperClass, position && styles["position_"+position])}>
      <div onClick={toggleSelect} className="cursor-pointer selected">
        <Image width={30} height={30} src={selected.path || ""} alt={selected.name} />
      </div>
      {/* {visible && */}
        <motion.div
          className={styles.drop_down_select}
          initial={{
            scaleY: 0,
          }}
          animate={visible ? "open" : "closed"}
          variants={variants}
        >
          {renderData(data)}
        </motion.div>
      {/* } */}
    </div>
  );
};

export default DropDownList;