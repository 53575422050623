import React from "react";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { Search } from "react-bootstrap-icons";
import { useRouter } from "next/router";
import styles from "./SearchForm.module.scss";

const SearchForm = ({ locale }) => {
  const router = useRouter();
  const onSubmit = (values) => {
    const formatValue = values.content?.trim();
    if (formatValue) {
      router.push(`/search?q=${formatValue}`);
    }
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          content: router.query.q || undefined,
        }}
        enableReinitialize={true}
      >
        <>
          <div>
            <Form>
              <Field
                name="content"
                className={classNames(
                  "py-1 pr-5 pl-3 rounded",
                  styles.searchForm
                )}
                placeholder={locale === "vi" ? "Tìm kiếm" : "Search"}
              />
              <button
                type="submit"
                className={classNames(
                  "position-absolute btn btn-link",
                  styles.submitButton
                )}
              >
                <Search className={styles.searchIcon} />
              </button>
            </Form>
          </div>
        </>
      </Formik>
    </>
  );
};

export default SearchForm;
